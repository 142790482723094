const { default: styled } = require("styled-components");

export const SubTitle = styled.p`
  font-size: 0.8em;
  font-weight: normal;
  color: #000;

  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;