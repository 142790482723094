import React from "react";
import styled from "styled-components";
import Footer from "../../components/footer";
import { Marginer } from "../../components/marginer";
import HeaderMeta from "../../components/metatags/head";
import {Input,TextArea} from "../../components/input";
import { Navbar } from "../../components/navbar";
import { SectionTitle } from "../../components/sectionTitle";
import { SubTitle } from "../../components/subTitle";
import { Button } from "../../components/button";
import emailIcon from "../../images/icons/email.svg"

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top:3%;
  max-width: 100%;

  @media screen and (max-width: 800px) {
    max-width: 70%;
    flex-direction: column-reverse;
  }
`;

const ImgContent = styled.div`
  margin-left:45%;
  max-width: 100%;

  @media screen and (max-width: 800px) {
    max-width: 70%;
    flex-direction: column-reverse;
  }
`;

const SubContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-top:-2%;

  @media screen and (max-width: 800px) {
    max-width: 70%;
    flex-direction: column-reverse;
  }
`;

const Row = styled.div `
    display: flex;
`;

const Column = styled.div `
    flex: 50%;
    align-items:left;
    justify-content: center;

    @media screen and (max-width: 500px) {
        flex:50%;
    }
`;





const Contact = () => {
    return (
        <PageContainer>
            <HeaderMeta/>
            <Navbar/>
            <Content>
                <SectionTitle>WANT TO GET IN TOUCH WITH US?</SectionTitle>
            </Content>
            <SubContent>
            <SubTitle>Want to know more about our service? Questions not answered? We are here to help!</SubTitle>
            </SubContent>
            <Row>
            <Column><ImgContent><img src={emailIcon} alt="emailIcon" width="50%" height="30%" /></ImgContent></Column>
            <Column>
                <Input placeholder="First name" width="50%" height="30px" margin="5% 25px 25px 0"/>
                <Input placeholder="Last name" width="50%" height="30px" margin="0 25px 25px 0"/>
                <Input placeholder="Email" width="50%" height="30px" margin="0 25px 25px 0"/>
                <TextArea placeholder="What is your enquiry?" width="50%" height="30%" margin="0 25px 25px 0"/>
                <Button > Send</Button>
            </Column>
            </Row>
            <Marginer direction="vertical" margin="8em" />
            <Footer></Footer>
        </PageContainer>
      )
}

export default Contact;
