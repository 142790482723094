import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";

export function Logo(props) {
  const { inline, small } = props;

  return (
    <LogoContainer inline={inline} small={small}>
      <LogoText inline={inline} small={small}>
        Skideo
      </LogoText>
    </LogoContainer>
  );
}



const LogoContainer = styled.div`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  align-items: center;
`;

// const LogoImg = styled.img`
//   width: 8em;
//   height: 8em;

//   ${({ inline }) =>
//     inline &&
//     css`
//       width: 24px;
//       height: 24px;
//       margin-right: 6px;
//     `};

//   ${({ small }) =>
//     small &&
//     css`
//       width: 4.8em;
//       height: 4.8em;
//     `};
// `;

const LogoText = styled.div`
  margin-top: ${({ inline }) => (inline ? 0 : "6px")};
  font-size: ${({ inline, small }) =>
    inline ? "30px" : small ? "23px" : "40px"};
  color: ${({ inline }) => (inline ? "#923d41" : theme.primary)};
  font-weight: 900;
`;
